import { Subject } from 'rxjs'

interface IAlert {
  isShow: boolean
  type: AlertType
  message: string
}

const alertSubject = new Subject<IAlert>()

export const alertService = {
  onAlert,
  show,
  close,
}

export enum AlertType {
  Success,
  Danger,
}

function onAlert() {
  return alertSubject.asObservable()
}

function show(isSuccess: boolean, message: string) {
  const type = isSuccess ? AlertType.Success : AlertType.Danger
  alertSubject.next({
    isShow: true,
    type,
    message,
  })
}

function close(closeAlert: IAlert) {
  alertSubject.next({ ...closeAlert, isShow: false })
}
