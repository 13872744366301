import React from 'react'
import './fullloadingspinner.scss'

const FullLoadingSpinner: React.FC = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner" />
    </div>
  )
}

export default FullLoadingSpinner
